import { Box, Container, Grid } from '@mui/material';

import './StartWithWisexStyles.css';
import { START_WITH_US_BTN, START_WITH_US_BTN_ABOUT_US, X_IMG } from '../../../../Utils/constants';
import { Button } from '../../../Utils/MuiStylesComponents';
import { useNavigate } from 'react-router';

const StartWithWisex = ({ heading, headingSpan, text, InvestmentCalculatorStartWithWisex }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    if (localStorage.getItem('userData')) {
      navigate('/investment-opportunities');
    }
    else {
      navigate('/signup');
    }
  };
  const handleButtonClickAboutUs = () => {
    // if (localStorage.getItem('userData')) {
      window.open('https://www.linkedin.com/company/aurumwisex/', '_blank');
    // }
    // else {
    //   navigate('/signup');
    // }
  };
  return (
    <Box className={`StartWithUs InvestmentCalculatorStartWithWisex`}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box className="StartWithUsContainerLEft">
              <Box className='StartWithUsLeft'>
                <h1 className='StartWithUsLeftHeading'>{heading} </h1>
                <span className='StartWithUsLeftHeadingSpan'>{headingSpan}</span>
                {text && <p className='StartWithUsText'>{text}</p>}
              </Box>
              <Box sx={{ display: 'none' }}>
                {window.location.pathname !== '/about-us' ?
                  <Button color='primary' className="StartWithUsBtn" onClick={handleButtonClick}>{START_WITH_US_BTN}</Button>
                  :
                  <Button color='primary' className="StartWithUsBtn" onClick={handleButtonClickAboutUs}>{START_WITH_US_BTN_ABOUT_US}</Button>
                }                
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box className='StartWithUsRight'>
              <img loading='lazy' width={450} height={300} className='StartWithUsRightImg' src={X_IMG} alt='WixesLogo' />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box >
  );
};

export default StartWithWisex;